
import { Component, Vue } from 'nuxt-property-decorator'
import ScareBlock from '~/components/info-block/ScareBlock.vue'
import SearchBlock from '~/components/info-block/SearchBlock.vue'
import AppBlock from '~/components/info-block/AppBlock.vue'
import WarrantyBlock from '~/components/info-block/WarrantyBlock.vue'
import SafetyBlock from '~/components/info-block/SafetyBlock.vue'
import Banner from '~/components/Banner.vue'

@Component({
  components: {
    Banner,
    SafetyBlock,
    WarrantyBlock,
    AppBlock,
    SearchBlock,
    ScareBlock
  }
})
export default class extends Vue {
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/mobile-apps/qr-gos-oplata.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-block__links[data-v-19381f62]{display:flex;margin-top:16px}.info-block__links a+a[data-v-19381f62]{margin-left:8px}@media(min-width:992px){.info-block[data-v-19381f62]{background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right 70px center/130px;padding-bottom:50px;padding-right:230px}.info-block__links[data-v-19381f62]{display:none}}.info-block__img[data-v-19381f62]{background:#e2dff4}.text-blue[data-v-19381f62]{color:#3a9de9;font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
